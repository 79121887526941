// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

export const getCompaniesList = createAsyncThunk('appCompanies/getCompaniesList', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   const response = await axios.get(apiLink('getCompaniesList', params))

   return {
      params,
      data: response.data.companies,
      totalPages: response.data.total_elements
   }
})

export const getCompany = createAsyncThunk('appCompanies/getCompany', async id => {
  const response = await axios.get(apiLink({name: 'getCompany', value: id}))
  return response.data
})

export const addCompany = createAsyncThunk('appCompanies/addCompany', async (data) => {
  const response = await axios.post(apiLink('addCompany'), data)
  return response.data
})

export const activateCompany = createAsyncThunk('appCompanies/activateCompany', async (id) => {
  const response = await axios.put(apiLink({name: 'activateCompany', value: id}, {activate: ''}, false))
  return response.data
})

export const deactivateCompany = createAsyncThunk('appCompanies/deactivateCompany', async (id) => {
  const response = await axios.put(apiLink({name: 'activateCompany', value: id}, {stop: ''}, false))
  return response.data
})

export const getSubscriptionsList = createAsyncThunk('appCompanies/getSubscriptionsList', async () => {  
  const response = await axios.get(apiLink('getSubscriptionsList', {    
    sort_order: "ASC",
    sort_list: "id",
    page: 0,
    rows: 100
  }))

  return {data: response.data.subscriptions}
})

export const getCompanyDetails = createAsyncThunk('appCompanies/getCompanyDetails', async data => {   
  const response = await axios.post(`http://164.90.210.46/core/company?env=${process.env.REACT_APP_NODE_ENV}&gate=core`, data)
  return response.data
})

export const getCompanyActivity = createAsyncThunk('appCompanies/getCompanyActivity', async data => {   
  const response = await axios.post(`http://164.90.210.46/core/company-activity?env=${process.env.REACT_APP_NODE_ENV}&gate=core`, data)
  return response.data
})

export const getCompanyNewList = createAsyncThunk('appCompanies/getCompanyNewList', async params => {   
  params.page = params.page === 0 ? 0 : params.page - 1
  const response = await axios.post(`http://164.90.210.46/core/company-list?env=${process.env.REACT_APP_NODE_ENV}&gate=core`, params)

  return {
     params,
     data: response.data.companies,
     totalPages: response.data.total_elements
  }
})

export const appCompaniesSlice = createSlice({
  name: 'appCompanies',
  initialState: {
    data: [],    
    total: 1,
    params: {},    
    selectedCompany: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCompaniesList.fulfilled, (state, action) => {
         state.data = action.payload.data
         state.params = action.payload.params
         state.total = action.payload.totalPages
      })
      .addCase(getCompanyNewList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
     })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.selectedCompany = action.payload
     })
  }
})

export default appCompaniesSlice.reducer
